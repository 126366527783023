import React from 'react'

import Layout from '../components/layout'

import AboutImage from '../images/about.jpg'

/*const setLevel = (level) => {

  document.body.setAttribute('data-prop-articlelevel', level)

}


setLevel(102)*/

const About = () => (
  <Layout articlelevel={100}>
    <>
      <div className="cell imageWrapper">
        <img
          width="600"
          height="966"
          src="/assets/about.jpg"
          alt="Anna Nilsson, chefredaktör"
          srcSet={AboutImage}
          sizes="(max-width: 600px) 100vw, 600px"
        />
      </div>
      <div className="cell contentWrapper">
        <div className="content whiteText">
          <section className="">
            <h1>
              Vi finns för dig oavsett produktion och var du driver ditt
              lantbruk.
            </h1>
            <p>
              <strong>
                Lantmannen skildrar bredden och spetskompetensen inom
                lantbruket.
              </strong>{' '}
              I Lantmannen får du ett helhetsgrepp inom teknik,&nbsp;forskning,
              ekonomi och växtodling.
            </p>
            <p>
              <strong>
                Du möter spännande innovativa lantbruksföretagare och
                profiler&nbsp;i branschen.{' '}
              </strong>
              Lantmannen är magasinet som går på djupet för att&nbsp;ge
              inspiration och kunskap till den professionella lantbrukaren.
            </p>
            <p className="libre">Malin Eborn, reporter</p>
          </section>
        </div>
      </div>
    </>
  </Layout>
)

export default About
